/* eslint-disable */
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const FarmerRegistration = lazy(() => import('../../container/farmer/farmerRegistration'));
const FarmerList = lazy(() => import('../../container/farmer/viewFarmer'));
const FarmerUpdate = lazy(() => import('../../container/farmer/farmerUpdate'));
const FarmerDetail = lazy(() => import('../../container/farmer/viewFarmer/detailFarmer'));
const FarmerRegistrationFromFarmhub = lazy(() => import('../../container/farmer/farmerRegistrationFromFarmhub'));
function FarmerRoute() {
  return (
    <Routes>
      <Route path="registration" element={<FarmerRegistration />} />
      <Route path="farmhubfarmer/:farmhubId" element={<FarmerRegistrationFromFarmhub />} />
      <Route path="list" element={<FarmerList />} />
      <Route path="edit/:NationalId" element={<FarmerUpdate />} />
      {/* <Route path="detail/:id" element={<FarmerUpdate />} /> */}
      <Route path="view/:NationalId" element={<FarmerDetail />} />
    </Routes>
  );
}

export default FarmerRoute;
